const animation = 'webkitAnimationEnd oanimationend msAnimationEnd animationend'

const $siteMenuLink = $('.site-menu__link')
const menuLinkActive = 'site-menu__link_state_active'

const $siteMenuPopupLink = $('.site-menu-popup__close')

const $siteMenu = $('.site-menu-popup')
const siteMenuVisible = 'site-menu-popup_state_visible'
const siteMenuHidden = 'site-menu-popup_state_hidden'

const $siteMenuFader = $('.site-menu__fader')
const siteMenuFaderVisible = 'site-menu__fader_state_visible'
const siteMenuFaderHidden = 'site-menu__fader_state_hidden'

function closeSiteMenu () {
  $siteMenuLink.removeClass(menuLinkActive)
  $siteMenuFader.addClass(siteMenuFaderHidden)
  $siteMenu.addClass(siteMenuHidden).on(animation, () => {
    $siteMenuFader.removeClass([siteMenuFaderVisible, siteMenuFaderHidden].join(' '))
    $siteMenu.removeClass([siteMenuVisible, siteMenuHidden].join(' ')).off(animation)
  })
}

function openSiteMenu () {
  $siteMenuLink.addClass(menuLinkActive)
  $siteMenuFader.addClass(siteMenuFaderVisible)
  $siteMenu.addClass(siteMenuVisible)
}

$siteMenuLink.on('click', function () {
  if ($(this).hasClass(menuLinkActive)) {
    closeSiteMenu()
  } else {
    openSiteMenu()
  }
  return false
})

$siteMenuPopupLink.on('click', () => {
  closeSiteMenu()
})


$(document).on('click', (event) => {
  const $siteMenuPopup = $(event.target).closest($siteMenu)
  if (!$siteMenuPopup.length && $siteMenu.is(':visible')) {
    closeSiteMenu()
  }
})
