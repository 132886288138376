'use strict'

const animation = 'webkitAnimationEnd oanimationend msAnimationEnd animationend'

const $hamburgerButton = $('.main-menu-hamburger')
const hamburgerButtonActiveClass = 'main-menu-hamburger_state_active'

let $mobileMenu = $('.main-menu')
let mobileMenuVisibleClass = 'main-menu_state_visible'
let mobileMenuHiddenClass = 'main-menu_state_hidden'

if ($('.main-menu_type_mobile').length) {
  $mobileMenu = $('.main-menu_type_mobile')
  mobileMenuVisibleClass = 'main-menu_type_mobile_state_visible'
  mobileMenuHiddenClass = 'main-menu_type_mobile_state_hidden'
}

function closeMobileMenu () {
  $hamburgerButton.removeClass(hamburgerButtonActiveClass)
  $mobileMenu
    .addClass(mobileMenuHiddenClass)
    .on(animation, () => {
      $mobileMenu.removeClass([mobileMenuVisibleClass, mobileMenuHiddenClass].join(' ')).off(animation)
    })
}

function openMobileMenu () {
  $hamburgerButton.addClass(hamburgerButtonActiveClass)
  $mobileMenu.addClass(mobileMenuVisibleClass)
}

$hamburgerButton.on('click', function () {
  if ($(this).hasClass(hamburgerButtonActiveClass)) {
    closeMobileMenu()
  } else {
    openMobileMenu()
  }
  return false
})

$(document).on('click', (event) => {
  const $menu = $(event.target).closest('.main-menu_type_mobile')
  if (!$menu.length && $mobileMenu.is(':visible')) {
    closeMobileMenu()
  }
})
