'use strict'

function scrollTo (target) {
  let dest = 0
  let padding = 0

  if ($('.header').data('sticky')) {
    padding = $('.header').outerHeight()
  }

  if (target.offset().top > $(document).height() - $(window).height()) {
    dest = $(document).height() - $(window).height()
  } else {
    dest = target.offset().top - padding
  }
  $('html, body').animate({ scrollTop: dest }, 2000, 'swing')
}

$(document).on('click', '.main-menu__item-link', function (event) {
  const href = $(this).attr('href')

  const $target = $(this.hash)

  if (href.indexOf('#') + 1 && $target.length) {
    event.preventDefault()

    scrollTo($target)

    window.location.hash = href.substr(2)
  }
})
